import { addEventDelegate } from "../premmio/public/modules/eventDelegate/eventDelegate.js";

const highlightRewards = (slides) => {
  const active = slides.dataset.active,
    rewards = document.querySelector("#rewards");

  rewards.dataset.active = active;
};

addEventDelegate(
  "attributes:data-active",
  ".patreonTiers .slider .slides",
  highlightRewards
);

// const getHomepageProducts = () => {
//   const success = (request) => {
//     const collection = JSON.parse(request.response),
//       products = collection.products,
//       newProducts = document.querySelector("#newProducts");

//     products.forEach((product) => {
//       const summary = renderTemplate(
//         ProductSummary({
//           data: product,
//           url: "https://shop.legendsofavantris.com/products/",
//         })
//       );

//       newProducts.appendChild(summary);
//     });
//   };

//   xhr({
//     path: "/premmio/shop/collection",
//     method: "POST",
//     body: { collectionHandle: "featured", count: 6 },
//     success,
//   });
// };

// getHomepageProducts();
